import React from "react";
import { Typography } from "@material-ui/core";
import NewsItem from "./NewsItem";
import { parseISO } from "date-fns";

export default ({ classes }) => {
  return [
    <NewsItem first time={parseISO("2024-08-23T16:06:00")} classes={classes}>
      <Typography gutterBottom>Epoch 504 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>80%</b>. Less than predicted as we lost 2 slot battles, this was unavoidable. This epoch’s rewards will be on the <b>27th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>51%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-18T18:01:00")} classes={classes}>
      <Typography gutterBottom>Epoch 503 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Slighty less than predicted as we lost 1 slot battle, this was unavoidable. This epoch’s rewards will be on the <b>22nd August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>95%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-08T23:00:00")} classes={classes}>
      <Typography gutterBottom>Epoch 502 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>86%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>17th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>1.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,

    <NewsItem first time={parseISO("2024-08-08T23:00:00")} classes={classes}>
      <Typography gutterBottom>Epoch 501 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>86%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>12th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>66%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>1.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>256%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>12th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-04T20:01:00")} classes={classes}>
      <Typography gutterBottom>Epoch 500 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>111%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>7th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>88%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>256%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>7th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>256%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-30T11:43:00")} classes={classes}>
      <Typography gutterBottom>Epoch 499 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>118%</b>. This epoch represents an annualised return of{" "}
            <b>3.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>111%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>2nd August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>256%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-24T20:38:00")} classes={classes}>
      <Typography gutterBottom>Epoch 498 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>139%</b>. This epoch represents an annualised return of{" "}
            <b>3.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>118%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-19T21:52:00")} classes={classes}>
      <Typography gutterBottom>Epoch 497 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>81%</b>. This epoch represents an annualised return of{" "}
            <b>2.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>139%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>23rd July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>118%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-14T21:14:00")} classes={classes}>
      <Typography gutterBottom>Epoch 496 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>95%</b>. This epoch represents an annualised return of{" "}
            <b>2.6%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>81%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>18th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>139%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>1.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-09T12:58:00")} classes={classes}>
      <Typography gutterBottom>Epoch 495 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>88%</b>. This epoch represents an annualised return of{" "}
            <b>2.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>95%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>13th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>81%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>256%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>13th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-04T11:19:00")} classes={classes}>
      <Typography gutterBottom>Epoch 494 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>73%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>88%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>8th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>256%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-29T16:14:00")} classes={classes}>
      <Typography gutterBottom>Epoch 493 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of{" "}
            <b>1.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>73%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>3rd July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>88%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>769%</b>. This epoch represents an annualised return of{" "}
            <b>18%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-24T22:14:00")} classes={classes}>
      <Typography gutterBottom>Epoch 492 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>110%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>513%</b>. This epoch represents an annualised return of{" "}
            <b>9.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>769%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-19T09:23:00")} classes={classes}>
      <Typography gutterBottom>Epoch 491 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>110%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>110%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>23rd June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>66%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>513%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>23rd June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>769%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-14T10:57:00")} classes={classes}>
      <Typography gutterBottom>Epoch 490 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>132%</b>. This epoch represents an annualised return of{" "}
            <b>3.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>110%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>18th June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>110%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>513%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-09T18:46:00")} classes={classes}>
      <Typography gutterBottom>Epoch 489 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>103%</b>. This epoch represents an annualised return of{" "}
            <b>2.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>132%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>13th June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>110%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-06-05T12:38:00")} classes={classes}>
      <Typography gutterBottom>Epoch 488 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>81%</b>. This epoch represents an annualised return of{" "}
            <b>2.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>103%</b>. Slightly less than
            predicted as we lost one slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>8th June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>132%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-05-31T16:28:00")} classes={classes}>
      <Typography gutterBottom>Epoch 487 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>95%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>81%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>3rd June</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>110%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-05-25T19:29:00")} classes={classes}>
      <Typography gutterBottom>Epoch 486 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>73%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>95%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>24th May</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>81%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
  ];
};
